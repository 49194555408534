{
    "left": "Links",
    "right": "Rechts",
    "supplier": "Leverancier",
    "modelHeritage": "Model komt van",
    "modelNumber": "Modelnummer",
    "description": "Beschrijving",
    "collarPadding": "Kraagpolster",
    "collarPaddingMaterial": "Kraagpolster soort",
    "collarPaddingThickness": "Kraagpolster dikte",
    "solePadding": "Tongpolster",
    "solePaddingThickness": "Tongpolster dikte",
    "upperHeight": "Schachthoogte",
    "stock": "Bestellen/ voorraad",
    "brand": "Merk",
    "shoeType": "Schoentype",
    "productNr": "Artikel nummer",
    "last": "Leest type",
    "color": "Kleur",
    "soleType": "Zool type",
    "sizeLeft": "Maat links",
    "sizeRight": "Maat rechts",
    "widthLeft": "Wijdte links",
    "widthRight": "Wijdte rechts",
    "insole": "Steunzool",
    "developMethod": "Maakwijze",
    "insoleType": "Soort",
    "stiffness": "Hardheid",
    "soleSpecification": "Uitvoering",
    "soleMaterial": "Materiaal",
    "correction": "Correctie",
    "correctionHeight": "Correctie hoogte",
    "heelLift": "Hiel heffing",
    "methodHeel": "Maakwijze hielpartij",
    "methodHeelSize": "Hielpartij (aantal mm)",
    "coverMaterial": "Afdekmateriaal/ bekleding",
    "heelSpur": "Hielspoor", 
    "transverseSupportMaterial": "Transversaalsteun materiaal",
    "transverseSupport": "Transversaalsteun",
    "pelot": "Pelotte",
    "toeBarMaterial" : "Teendorpel materiaal",
    "toeBar": "Teendorpel",
    "methodFront": "Maakwijze voorvoet",
    "methodFrontSize": "Maakwijze voorvoet (aantal mm)",
    "methodFull": "Maakwijze geheel",
    "methodFullSize": "Maakwijze geheel (aantal mm)",
    "rocker": "Afwikkelbalk",
    "heel": "Hiel",
    "ball": "Bal",
    "toe": "Teen",
    "waist": "Teensprong",
    "heelAdjustment": "Hak aanpassing",
    "stretch": "Oprekken",
    "solePad": "Tongpolster",
    "closureAdjust": "Sluiting aanpassen",
    "tongueVelcro": "Klittenband aan tong",
    "replaceAdhesive": "Kleefband vervangen",
    "extendVelcro": "Klittenband verlengen",
    "shortenVelcro": "Klittenband verkorten",
    "openPosition": "Openstand",
    "lowerUpper": "Schacht verlagen",
    "lateral": "Hakschoring lateraal",
    "medial": "Hakschoren mediaal",
    "soleStiffening": "Zoolverstijving",
    "existingLast": "Bestaande leesten",
    "updateLast": "Aanpassen leesten",
    "PLT": "PLT",
    "ELI": "ELI",
    "supplementCreator": "Supplementen maker",
    "level": "Uitvlakken op stand",
    "heelHeight": "Hakhoogte (mm)",
    "heelThickness": "Dikte hiel",
    "ballThicknessMedial": "Dikte bal mediaal",
    "ballThicknessLateral": "Dikte bal lateraal",
    "material": "Materiaal",
    "contrefort": "Contrefort",
    "schoring": "Schoring",
    "heelSchoring": "Hak schoring",
    "secondSupplement": "2de supplement",
    "fittingShoe": "Passchoen",
    "widhtSole": "Ruimtezool",
    "insideSole": "Binnenzool",
    "contrefortMaterial": "Contrefort materiaal",
    "anklePaddingMaterial": "Enkelpostering materiaal",
    "nose": "Neus/ neuzen",
    "noseMaterial": "Neus materiaal",
    "sideLining": "Zijvoering",
    "edges": "Randen",
    "edgesMaterial": "Randen materiaal",
    "midSole": "Tussenzool",
    "outSole": "Loopzool",
    "soleNumber": "Zoolnummer",
    "toeParts": "Teenstukken",
    "heelMaterial": "Hak materiaal",
    "heelModel": "Hak model",
    "mudguard": "Spatrand",
    "mudguardMaterial": "Spatrand materiaal",
    "heelStructure": "Hak opbouw",
    "heelBuffer": "Hak buffer",
    "backflap": "Achterlap",
    "cambering": "Cambering",
    "anklePadding": "Enkelpostering",
    "schoringCTLateral": "Schoring CT lat",
    "schoringCTMedial": "Schoring CT med",
    "boardSize": "Boordmaat",
    "woodenHeelType": "Houten haktype",
    "schoringLat": "Schoring lateraal",
    "schoringMed": "Schoring mediaal",
    "rounding": "Afronden (mm)",
    "therapist": "Behandelaar",
    "name": "Naam",
    "email": "Emailadres",
    "gender": "Geslacht",
    "fittingLocation": "Aanmeetlocatie",
    "receiveLocation": "Afleverlocatie",
    "initials": "Initialen",
    "placeName": "Plaatsnaam",
    "title": "Ortho-IT titel",
    "id": "Ortho-IT ID",
    "emergencyOrder": "Spoedbestelling",
    "entryDate": "Invuldata",
    "deliveryDate": "Aanleverdatum",
    "readyDate": "Datum gereed",
    "appointmentDate": "Afspraak datum",
    "transverseSupportHeight": "Transversaalsteun hoogte",
    "pelotHeight": "Pelotte hoogte",
    "distanceToHeel": "Afstand tot hak",
    "toeTreshold": "Teendorpel",
    "toeTresholdMaterial": "Teendorpel materiaal",
    "heelCurb": "Hielstoepje",
    "heelCurbMaterial": "Hielstoepje materiaal",
    "toeCutout": "Uitdiepen tenen",
    "toes": "Tenen",
    "front": "Voor",
    "complete": "Geheel",
    "finishingSoleStiffening": "Zoolverstijving",
    "rubberSole": "Zool rubber",
    "leatherSole": "Zool leer",
    "heels": "Hak",
    "generalSole": "Zool algemeen",
    "heelRounding": "Hakafronding",
    "heelLining": "Hielvoering",
    "tongueStitching": "Tong vaststikken",
    "repairStitching": "Stikstel herstellen",
    "externalLining": "Voering extern gemaakt",
    "closingMechanism": "Sluiting schoen",
    "footwearType": "Type passchoen",
    "lining": "Voering",
    "liningMaterial": "Voering materiaal",
    "tonguePad": "Tongpolster",
    "tonguePadMaterial": "Tongpolster materiaal",
    "tongueReinforcement": "Tongversteveging",
    "entryPoint": "Inschotpunt",
    "soleElevationHeel": "Hakzool verhoging hiel",
    "soleElevationBall": "Hakzool verhoging bal",
    "soleElevationToe": "Hakzool verhoging teen",
    "addedLenght": "Neusverlenging",
    "technicalDrawing": "Werktekening aanwezig",
    "customizePattern": "Patroon aanpassen",
    "mainLeather": "Overleer hoofdsoort",
    "firstExtraLeather": "Overleer bijsoort 1",
    "secondExtraLeather": "Overleer bijsoort 2",
    "thirdExtraLeather": "Overleer bijsoort 3",
    "number": "Nummer",
    "collarPaddingType": "Kraagpolster soort",
    "birthdate": "Geboortedatum",
    "otherSole": "Ander type loopzool",
    "orderType": "Bestel formulier",
    "lastName": "Achternaam",
    "patientData": "Algemeen/ patient informatie",
    "osbShoeData": "OSB Schoen",
    "osbOutsoleData": "Aanpassing onderwerk/ schacht",
    "insoleData": "Steunzool",
    "sampleShoeData": "Proef/ voeringschoen",
    "osaPatternData": "OSA Patroon",
    "osaSupplementData": "OSA Supplement",
    "osaLowerData": "OSA onderwerk en TVO",
    "osaFinishData": "OSA Afwerking",
    "repairData": "Reparatie",
    "note": "Opmerking",
    "orderNumber": "Order nummer",
    "selectedLast": "Leest nummer",
    "toeThickness": "Dikte teen",
    "laces": "Veters",
    "velcro": "Klittenband",
    "zipper": "Rits",
    "elastic": "Elastiek",
    "buckle": "Gesp",
    "reverseVelcro": "Klittenbanden omkeren",
    "laceRings": "Ringen",
    "laceHooks": "Haken",
    "mudguardLeather": "Spatrand leer",
    "velcroAmount": "Klittenband aantal",
    "velcroWidth": "Klittenband breedte",
    "version": "Uitvoering",
    "changedLasts": "Leesten aangepast (invoerdatum)",
    "medialLenghtStraight": "Mediaal geleng recht houden",
    "allergyFree": "Allergie vrij",
    "noteLasts": "Aanpassen leesten",
    "ownedShoeBrand": "Eigen schoen merk",
    "ownedShoeColor": "Eigen schoen kleur",
    "ownedShoeSize": "Eigen schoen maat",
    "longDistanceWalking": "Lange afstand lopen",
    "fileName": "Bestandsnaam",
    "sendDate": "Verstuur datum"
}